<template>
  <div class="promise-details">
    <van-nav-bar left-arrow :title="title" @click-left="$router.back()" />
    <van-form
      :show-error-message="false"
      label-width="50vw"
      class="van-form__text-color wrapper-height__not-feature-btn"
    >
      <van-field
        :value="info.departmentName"
        disabled
        label="部门名称"
        input-align="right"
      />
      <van-field disabled label="状态" input-align="right">
        <template #input>
          <span
            :class="[
              +info.status === 0 ? 'un-promise-color' : 'promised-color'
            ]"
            >{{ info.statusValue }}</span
          >
        </template>
      </van-field>
      <van-field disabled label="承诺人" input-align="right">
        <template #input>
          <span class="disabled-color">{{
            +info.status === 0 ? "" : info.uuserName
          }}</span>
        </template>
      </van-field>
      <van-field disabled label="承诺时间" input-align="right">
        <template #input>
          <span class="disabled-color">{{
            +info.status === 0 ? "" : info.utime
          }}</span>
        </template>
      </van-field>
      <van-field
        v-model="info.remark"
        disabled
        label="补录原因"
        input-align="right"
        :autosize="true"
        type="textarea"
      />
      <div v-for="conf in promiseDetailsConf" :key="conf.type">
        <template v-if="detailClassMap[conf.type]">
          <h3 class="promise-details__title">{{ conf.title }}</h3>
          <div>
            {{ void (filterValue = filterConf(conf)) }}
            <van-field
              v-for="(item, idx) in filterValue"
              :key="idx"
              disabled
              :label="item.label"
              input-align="right"
            >
              <template #input>
                <span class="disabled-color">{{
                  dealValue(item, conf.type)
                }}</span>
              </template>
            </van-field>
          </div>
        </template>
      </div>
      <div v-if="+info.riskTroubleDescFlag">
        <h3 class="promise-details__title">安全风险及隐患描述</h3>
        <van-field disabled input-align="left">
          <template #input>
            <span class="disabled-color">{{
              info.riskTroubleDesc || "--"
            }}</span>
          </template>
        </van-field>
      </div>
      <div v-if="+info.controlMeasuresFlag">
        <h3 class="promise-details__title">管控措施</h3>
        <van-field disabled input-align="left">
          <template #input>
            <span class="disabled-color">{{
              info.controlMeasures || "--"
            }}</span>
          </template>
        </van-field>
      </div>
      <div v-if="+info.signatureFlag">
        <h3 class="promise-details__title">承诺人签名</h3>
        <van-field disabled input-align="left">
          <template #input>
            <span v-if="info.signature" class="sign-style" @click="signToggle"
              >电子签名<i class="iconfont iconfont-icoqianming qianming"
            /></span>
            <span v-else class="disabled-color">--</span>
          </template>
        </van-field>
      </div>
      <div>
        <h3 class="promise-details__title">风险等级</h3>
        <van-field disabled input-align="right">
          <template #label>
            <span>风险等级</span>
          </template>
          <template #input>
            <span class="disabled-color">{{ info.riskGradeName || "--" }}</span>
          </template>
        </van-field>
      </div>
      <div>
        <h3 class="promise-details__title">承诺内容</h3>
        <van-field
          disabled
          input-align="right"
          label="承诺内容"
          maxlength="500"
          name="content"
          label-width="20vw"
          placeholder="请输入内容"
          rows="2"
          type="textarea"
          :autosize="true"
          class="promise-details__content"
        >
          <template #input>
            <span class="disabled-color">{{ info.content || "--" }}</span>
          </template>
        </van-field>
      </div>
    </van-form>
  </div>
</template>

<script>
import { promiseDetailsConf, controlDic } from "@/views/promise/utils/constant";
import { getPromiseInfoById } from "@/api/psm";
import { whetherDic as yesNoDic } from "@/utils/constant";
import { fileUrl } from "@/utils/constant";
import esign from "@/components/esign";

export default {
  name: "PromiseDetails",
  props: ["id"],
  data() {
    return {
      title: "研判与承诺信息详情",
      promiseDetailsConf,
      controlDic,
      yesNoDic,
      info: {},
      detailClassMap: {
        equipmentSafeVO: false,
        equipmentSafeRunVO: false,
        poiSafeRunVO: false,
        workSafeVO: false,
        othersVO: false
      }
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    signToggle() {
      esign.showImage(fileUrl + this.info.signature);
    },
    async getInfo() {
      try {
        this.info = await getPromiseInfoById(this.id);
        this.processClassDisplay();
        this.getSign();
      } catch (e) {
        console.log(e);
      }
    },
    getSign() {
      if (this.info.signature) {
        //已上传过，直接预览
        esign.showImage(fileUrl + fileId);
        return;
      }
    },
    trueValue(value) {
      return value !== undefined && +value !== -1;
    },
    // 模板用处理类别下是否要展示的值
    filterConf(conf) {
      return conf.children.filter(x => {
        return this.trueValue(this.info[conf.type][x.prop]);
      });
    },
    // 根据数据处理类别标题的显隐
    processClassDisplay() {
      this.promiseDetailsConf.forEach(item => {
        const type = item.type;
        this.detailClassMap[type] = item.children.some(x => {
          return this.trueValue(this.info[type][x.prop]);
        });
      });
    },
    dealValue(conf, type) {
      const prop = conf.prop;
      const value = this.info[type][prop];
      if (conf.type === "yesOrNo") {
        return yesNoDic[value];
      } else if (conf.type === "controlDic") {
        return controlDic[value];
      } else {
        if (conf.hidden) {
          const { status } = this.info;
          return +status === 1 ? value : "";
        }
        return value;
      }
    }
  }
};
</script>

<style lang="scss">
.promised-color {
  color: #8c8f97;
}
.un-promise-color {
  color: #ff6d1e;
}
.promise-details {
  .van-form {
    overflow: auto;
  }
  .van-cell {
    padding: 12px 16px;
  }
  .van-cell__title,
  .van-cell__value .van-field__body > *:not(textarea) {
    // height: 30px;
    line-height: 30px;
  }
  .promise-details__content {
    .van-cell__value .van-field__body .van-field__control {
      height: auto;
      text-align: left;
    }
  }
  &__title {
    line-height: 30px;
    padding-left: 16px;
    font-weight: bold;
    color: #2e2e4d;
    font-size: 14px;
    background-color: #eceef2;
  }
}
.sign-style {
  color: #2e2e4d;
  .qianming {
    position: relative;
    top: 2px;
    left: 3px;
    color: #2eb752;
  }
}
</style>
